import React, { useState, useEffect } from "react";
import industryDetailImg from "../Assets/industryDetail.jpg";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import "./Css/BlogDetail.css";
import "./Css/IndustryDetail.css";
import { useLocation } from "react-router-dom";
export default function RealStateIndustry() {
  const location = useLocation();
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");
  const [textWidth2, setTextwidth2] = useState("100%");
  const [h1Width, setH1Width] = useState("0%");

  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("whatWedo");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top >= 0 && h1Top <= windowHeight * 0.8) {
          setH1Width("100%");
        } else {
          setH1Width("0%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //RealStateData Industry Data
  const RealStateData = [
    {
      title: "Email and WhatsApp Marketing",
      content:
        "The real estate market is highly competitive, and buyers and sellers today are more tech-savvy than ever. With the majority of property searches starting online, having a strong digital presence is no longer optional—it’s a necessity. A well-executed Digital Marketing For Real Estate strategy ensures that your agency is visible, engaging, and accessible to potential clients at the exact moment they're searching for property solutions.",
    },
    {
      title: "Email and WhatsApp Marketing",
      content:
        "More than just traditional listings and flyers, digital marketing allows real estate businesses to tap into a much broader audience. By leveraging tools like SEO, social media, and pay-per-click ads, you can showcase your properties to the right people, at the right time. Whether you're a local real estate agent or a large agency, digital marketing is the most effective way to attract buyers and sellers, improve your brand's visibility, and ultimately grow your business.",
    },
    {
      title: "Email and WhatsApp Marketing",
      content:
        "At The Fox Advertisement, we specialize in creating customized digital marketing strategies specifically designed for real estate companies. Our services are built around increasing your online presence and driving more qualified leads to your business. Here's how we can help you.",
    },
  ];
  const RealStateData2 = [
    {
      title: "How The Fox Advertisement Can Help Your Business Grow",
      content:
        "In today's competitive real estate market, having a strong online presence is crucial for success. Property buyers and sellers rely heavily on the internet to search for agents, properties, and make informed decisions. At The Fox Advertisement, we specialize in offering comprehensive Digital Marketing For Real Estate services designed to help real estate companies attract more clients, close deals faster, and grow their business.",
      content2:
        "Our strategies focus on making your real estate agency visible where it matters most—online. Whether you're a small local agency or a large real estate company, our team at The Fox Advertisement can help you achieve your goals with effective digital marketing solutions.",
    },
    {
      title: "Why Choose Digital Marketing For Real Estate?",
      content:
        "The real estate industry has undergone a significant shift toward online engagement. Buyers and sellers are increasingly turning to search engines, social media, and real estate listing sites to find properties and agents. Without a strong digital marketing strategy, real estate businesses risk missing out on valuable leads.",
      content2:
        "The Fox Advertisement is a trusted Digital Marketing Company For Real Estate Agencies that understands the unique needs of the real estate market. We know how to get your listings seen by potential buyers, attract sellers, and help your agency stand out in a crowded marketplace. Here’s how we do it:",
    },
  ];

  ///main data
  const data = [
    {
      title: "Search Engine Optimization (SEO):",
      description:
        "We optimize your website with relevant keywords, content, and technical SEO to improve its ranking on Google and other search engines. When potential buyers and sellers search for real estate services in your area, your website will appear higher in search results, driving more organic traffic to your site. This helps ensure that your business is visible to those actively looking for property services.",
    },
    {
      title: "Targeted Advertising",
      description:
        " Our team creates highly targeted pay-per-click (PPC) ads and social media campaigns aimed at potential buyers and sellers in your region. We ensure that your ads appear to the right audience, increasing the chances of getting inquiries. This allows you to connect with people who are already interested in buying or selling real estate.",
    },
    {
      title: "Content Marketing",
      description2:
        "By partnering with The Fox Advertisement, a leading Digital Marketing Agency For Real Estate, you’ll not only increase your visibility but also attract more qualified leads-  people who are genuinely interested in buying or selling properties.",
      description:
        " We create engaging blog posts, articles, and property listings that highlight your expertise and services. High-quality content is a powerful way to attract customers and build trust. By offering valuable information about the real estate market, you position yourself as an authority in the field, making customers more likely to choose your agency.",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth2("100%");
      setTextwidth("90%");
      setTextwidth2("0");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const [visibleItems, setVisibleItems] = useState([]);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibleItems((prev) => [...prev, entry.target.id]);
      } else {
        setVisibleItems((prev) => prev.filter((id) => id !== entry.target.id));
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.8, // 80% of the element should be visible
    });

    const elements = document.querySelectorAll(".item");
    elements.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="blogDetaildiv">
      <Header />
      <div className="searvicDEtailDiv" style={{ overflow: "hidden" }}>
        <div className="heroServive">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="leftService">
                <div
                  className="overlay"
                  style={{
                    width: overlayWidth,
                    transition: "width 1.3s ease-in-out",
                  }}
                ></div>
                <div className="imgOverlAy"></div>
                <img
                  src={industryDetailImg}
                  alt="The Fox Advertisement"
                  title="The Fox Advertisement"
                  loading="lazy"
                  className="responsive-img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="rightService">
                <div className="detailServHeroSubBlogg">
                  <div
                    className="overlaytEXT"
                    style={{
                      width: textWidth2,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>

                  {/**-----------Reall state  Url Heading------------ */}

                  <div>
                    <h2>Why Digital Marketing For Real Estate is Crucial ?</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <div className="avhivedDiv">
          {/*--------------Real State data Top--------------- */}
          {RealStateData.map((section, index) => (
            <div key={index} className="blogDetaiuldata">
              <p>{section.content}</p>
            </div>
          ))}
          {RealStateData2.map((section, index) => (
            <div key={index} className="RealIndiData">
              {" "}
              <h5>{section.title}</h5>
              <p>{section.content}</p>
              <p className="mt-2">{section.content2}</p>
            </div>
          ))}
        </div>
        <h3 className="mt-3 " style={{ fontSize: "30px", textAlign: "center" }}>
          How We Improve Your Online Presence
        </h3>
        <p style={{  textAlign: "center" }}>
          Your online presence is often the first impression potential clients
          have of your real estate business. If your website is outdated, hard
          to navigate, or doesn't appear on the first page of search results,
          you could be losing out on clients. At The Fox Advertisement, we
          specialize in creating and enhancing your online presence through
          various strategies:
        </p>
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Website Design and Optimization</strong>

            <p>
              We create modern, mobile-friendly websites that are easy to
              navigate and optimized for both speed and search engines. A
              professional website will help build credibility with potential
              clients, making them more likely to trust you with their real
              estate needs.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Local SEO</strong>

            <p>
              Local SEO ensures that your real estate business shows up in
              search results when people in your area look for real estate
              services. We focus on optimizing your website and Google My
              Business profile to ensure you're seen by local clients.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Social Media Management</strong>

            <p>
              We manage your social media platforms, including Facebook,
              Instagram, and LinkedIn, to create a consistent and professional
              online presence. Social media is a valuable tool for engaging with
              your audience, showcasing new listings, and building your brand.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Social Media Management</strong>

            <p>
              We manage your social media platforms, including Facebook,
              Instagram, and LinkedIn, to create a consistent and professional
              online presence. Social media is a valuable tool for engaging with
              your audience, showcasing new listings, and building your brand.
              <br/>
              With The Fox Advertisement, a top Digital Marketing Company For Real
          Estate Agencies, you can rest assured that your online presence will
          be optimized to attract potential clients, making your real estate
          business the go-to choice in your area.
            </p>
          </div>
        </div>{" "}
       
      </div>
      <div className="container-lg"><h3 className="mt-3 " style={{ fontSize: "30px", textAlign: "center" }}>
        Why Should You Choose The Fox Advertisement for Your Real Estate
        Business?
      </h3>
      <p style={{  textAlign: "center" }}>
        There are many digital marketing agencies for real estate out there, but
        here's why The Fox Advertisement stands out:
      </p>
      
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Expertise in Real Estate Marketing</strong>
          <p>
            We understand the unique challenges faced by real estate businesses.
            From promoting listings to building brand trust, our team has years
            of experience in crafting effective digital marketing strategies for
            real estate companies.
          </p>
        </div>
      </div>{" "}
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Proven Results</strong>
          <p>
            Our strategies are data-driven, and we focus on delivering
            measurable results. We’ve helped numerous real estate companies
            increase their online visibility, generate more leads, and close
            more deals. Whether you need more sellers or buyers, we know how to
            get the results you’re looking for.
          </p>
        </div>
      </div>{" "}
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Comprehensive Services</strong>
          <p>
            We offer everything from SEO, social media management, and PPC
            advertising to website design and content creation. This ensures
            that all aspects of your digital marketing needs are covered under
            one roof, giving you a seamless and effective approach to growing
            your real estate business.
          </p>
        </div>
      </div>{" "}
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Affordable and Transparent</strong>
          <p>
            At The Fox Advertisement, we offer competitive pricing with no
            hidden fees. Our transparent reporting keeps you informed about the
            performance of your campaigns, so you know exactly what you're
            getting.
          </p>
        </div>
      </div>{" "}
      <h3 className="mt-3 " style={{ fontSize: "30px", textAlign: "center" }}>
        How Our Digital Marketing Services Will Benefit Your Real Estate
        Business ?
      </h3>
      <p style={{  textAlign: "center" }}>
        Our Digital Marketing For Real Estate Companies is designed to help you
        grow your business by reaching more clients, closing more deals, and
        establishing a strong reputation in the market. Here’s how our services
        will benefit you:
      </p>
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Increased Online Visibility</strong>
          <p>
            Our SEO and content strategies will help your website rank higher on
            search engines, ensuring more people find your real estate agency
            when they search for services.
          </p>
        </div>
      </div>{" "}
       
    
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>More Qualified Leads</strong>
          <p>
            Our targeted marketing campaigns are designed to reach people who
            are actively looking to buy or sell real estate, resulting in more
            qualified leads.
          </p>
        </div>
      </div>{" "}
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Improved Customer Engagement</strong>
          <p>
            By creating engaging content and managing your social media
            platforms, we help you stay connected with potential buyers and
            sellers, keeping your business top of mind.
          </p>
        </div>
      </div>{" "}
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Higher Conversion Rates</strong>
          <p>
            With a professional website and optimized marketing campaigns,
            you’ll be able to convert more visitors into actual clients,
            resulting in faster and more efficient sales.
          </p>
        </div>
      </div>{" "}
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Long-Term Growth</strong>
          <p>
            Our ongoing digital marketing efforts will help establish your
            business as a leader in the real estate market, ensuring sustained
            growth over time.
          </p>
        </div>
      </div>{" "}
      <h3 className="mt-3 " style={{ fontSize: "30px", textAlign: "center" }}>
        What Makes The Fox Advertisement Different?
      </h3>
      < p style={{  textAlign: "center" }}>
        Here’s why The Fox Advertisement is the ideal Digital Marketing Company
        For Real Estate Agencies:
      </p>
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Real Estate Industry Expertise</strong>
          <p>
            We specialize in working with real estate companies, so we
            understand your business, your market, and your clients.{" "}
          </p>
        </div>
      </div>{" "}
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Data-Driven Marketing</strong>
          <p>
            Our strategies are backed by data, ensuring that every decision we
            make is aimed at getting the best possible results for your
            business.
          </p>
        </div>
      </div>{" "}
      <div className="avhivedDiv2">
        {/*---------------Food Indutry Data---------------- */}

        <div className="blogDetaiuldata">
          <strong>Customer-Centric Approach</strong>
          <p>
            We listen to your goals, provide personalized solutions, and work
            together with you to achieve success.{" "}
          </p>
        </div>
      </div>{" "}
      

      </div>
     


      <div className="whatWeDo mt-4" id="whatWedo">
        <div className="whatWEdoHead">
          {" "}
          <h1
            style={{
              width: h1Width,
            }}
          >
            Boost Your Real Estate Business with More Customers
          </h1>
        </div>
        <p style={{ color: "#5A5C5C " }}>
          At The Fox Advertisement, we use proven digital marketing techniques
          to ensure your real estate business attracts more clients. Here’s how:
        </p>

       
       

        <div className="whatWeDoContent">
          <div className="container-lg">
            {data.map((item, index) => (
              <div
                className="row align-items-center justify-content-center marginClass "
                key={index}
              >
                <div
                  className={`col-lg-3 col-md-4 col-12 item paddingNoneee`}
                  id={`item-${index}`}
                >
                  <div className="underDataWhatwedo industryDetailcont">
                    <span>Proven Results</span>
                    <h1
                      style={{
                        opacity: visibleItems.includes(`item-${index}`) ? 1 : 0,
                        transition: "opacity 0.8s ease-in-out",
                      }}
                    >
                      {item.title}
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 col-12">
                  <p
                    style={{
                      opacity: visibleItems.includes(`item-${index}`) ? 1 : 0,
                      transition: "opacity 0.8s ease-in-out",
                    }}
                  >
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
            <p className="mt-4">
              By partnering with The Fox Advertisement, a leading Digital
              Marketing Agency For Real Estate, you’ll not only increase your
              visibility but also attract more qualified leads- people who are
              genuinely interested in buying or selling properties.
            </p>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <div className="Letgrowdata">
          <h1>Let’s Grow Your Real Estate Business Together</h1>
          <p>
            At The Fox Advertisement, we are committed to helping your real
            estate business succeed. Whether you’re looking to attract more
            buyers, get more listings, or simply improve your online presence,
            we have the tools and expertise to help you grow. As a leading
            Digital Marketing Agency For Real Estate, we offer comprehensive
            services that are tailored to meet your needs and bring you
            measurable results.
          </p>
          <p>
            If you're ready to take your real estate business to the next level,
            contact us today and see how The Fox Advertisement can help you
            achieve your goals with effective and affordable Digital Marketing
            For Real Estate Companies.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
