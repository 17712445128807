import React, { useState, useEffect, useRef } from "react";
import "./Css/RecentWork.css";
import recnt1 from "../Assets/recnt1.jpg";
import recnt2 from "../Assets/recnt2.jpg";
import recnt3 from "../Assets/recnt3.jpg";
import NavigationWithAnimation from "../CommonJquery/NavigationWithAnimation";
import { useLocation } from "react-router-dom";
const RecentWork = () => {
  const location = useLocation();
  const { handleNavigationRoute } = NavigationWithAnimation();

  const handleNavigation = () => {
    if (location.pathname === "/portfolio") {
      window.scrollTo(0, 0);
    } else {
      handleNavigationRoute("/portfolio");
    }
  };

  ///////////////////Maped data

  const recentWorks = [
    {
      imgSrc: recnt1,
      heading: "AI DRIVEN",
      subheading:
        "Driven Digital Marketing Solution | Best Digital Marketing Company in Bhopal",
      description:
        "Our targeted campaigns are a hit! Our team of digital experts work tirelessly to ensure that every campaign is optimised for success. With us, you are partnering with the best digital marketing company in India, one that is committed to helping you growing your business.",
    },
    {
      imgSrc: recnt2,
      heading: "NEUROLOGICAL",
      subheading: "Strategy & Branding",
      description:
        "Leveraging smart technology(AI), we have helped clients reach the targeted audience by understanding their vision and mission. Expert-led strategy and marketing boosted brand recognition and people know and identify our client’s awesome brands!",
    },
    {
      imgSrc: recnt3,
      heading: "LEAD & DEMAND GENERATION",
      subheading: "3X Leads | 10X traffic | 3X revenue:",
      description:
        "Digital Marketing is all about growth, that is exactly what we have delivered to our clients over the years. Through strategic planning, we have generated 5532 high-quality leads. These potential customers are genuinely interested in what our clients offer, keeping their businesses thriving.",
    },
    {
      imgSrc: recnt3,
      heading: "Performance Marketing",
      subheading: "Growth Hacking for better results:",
      description:
        "Constant tracking and changing our strategy to get our clients the best outcome is our motto. This approach has led to a mind-blowing improvement in their ROI.",
    },
  ];

  //////overlay class logic
  const [overlayWidths, setOverlayWidths] = useState(
    new Array(recentWorks.length).fill("100%")
  );
  const workRefs = useRef(recentWorks.map(() => React.createRef()));
  useEffect(() => {
    const handleScroll = () => {
      const newOverlayWidths = recentWorks.map((work, index) => {
        const element = workRefs.current[index].current;
        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const distanceFromTop = rect.top;
          if (distanceFromTop <= windowHeight * 0.95 && distanceFromTop >= 0) {
            return "0%";
          } else if (distanceFromTop > windowHeight * 0.95) {
            return "100%";
          }
        }
        return "100%";
      });

      setOverlayWidths(newOverlayWidths);
    };

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section className="recentWorkSection">
      <div className="Recentheading">
        <h2>RECENT WORK</h2>
        <p>WHAT WE HAVE TO OFFER</p>
      </div>

      {recentWorks.map((work, index) => (
        <div
          className="recnt1 marginBttm"
          key={index}
          ref={workRefs.current[index]}
        >
          <div
            className={`row justify-content-center ${
              index % 2 === 0 ? "flex-row-reverse" : ""
            } align-items-center`}
          >
            <div className="col-md-6 col-12">
              <div
                className="imgDivRecent position-relative BlackColorClass"
                id={work.id}
              >
                <div
                  className="overlay"
                  style={{ width: overlayWidths[index] }}
                ></div>
                <img
                  src={work.imgSrc}
                  alt={`Recent work showcasing ${work.heading} - ${work.subheading}`}
                  title={`Recent work: ${work.heading} - ${work.subheading}`}
                  className="recentImage"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="container-lg">
                <div
                  className={
                    index % 2 === 0 ? "textContainer" : "textContainer2"
                  }
                >
                  <div className="col-md-8 col-12">
                    <h2 className="for_h2">{work.heading}</h2>
                    <h2>{work.subheading}</h2>
                    <p>{work.description}</p>
                    <div className="explorBttn">
                      <button
                        className="explorBttn btn-push-left"
                        data-hover={work.heading}
                        href="#"
                        onClick={handleNavigation}
                      >
                        <span>EXPLORE </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default RecentWork;
