import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import "./Css/Blog.css";
import NavigationWithAnimation from "../CommonJquery/NavigationWithAnimation";
import { server_post_data, blog } from "../ServiceConnection/serviceconnection";
import { formatDateString, handleError } from "../CommonJquery/CommonJquery.js";
import Loader from "./Reapeting Componets/Loader";
export default function Blog() {
  const { handleNavigationRoute } = NavigationWithAnimation();

  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [blogData, setcancelledData] = useState([]);
  const [visibleBlogs, setVisibleBlogs] = useState(6);
  const [allBlogsLoaded, setAllBlogsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  console.log(SEOloop);
  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "blog/blogdetail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  useEffect(() => {
    master_data_get();
  }, []);
  function truncateWords(text, wordLimit) {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + " ..."
      : text;
  }
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 1);
    await server_post_data(blog, fd)
      .then((Response) => {
        console.log(Response);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          console.log(final_data);
          setcancelledData(final_data.blog);
          setSEOloop(final_data.seo_list);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  const handleLoadMore = () => {
    if (allBlogsLoaded) {
      setVisibleBlogs(6);
      setAllBlogsLoaded(false);
    } else {
      setLoading(true);

      setTimeout(() => {
        const newVisibleBlogs = visibleBlogs + 6;
        setVisibleBlogs(newVisibleBlogs);

        if (newVisibleBlogs >= blogData.length) {
          setAllBlogsLoaded(true);
        }
        setLoading(false);
      }, 550);
    }
  };
  const handleImageLoad = () => {
    setLoading(false); // Set loading to false when the image has loaded
  };
  return (
    <div>
      {showLoaderAdmin && <Loader />}
      <Header />
      <div className="mainBLog">
        <div className="container-lg">
          <div className="row ">
            {blogData &&
              blogData.length > 0 &&
              blogData.slice(0, visibleBlogs).map((blog, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-sm-6 col-md-6 col-12 "
                  style={{ marginTop: "2rem" }}
                >
                  <div className="blogData">
                    <div>
                      <LazyLoadImage
                        onClick={() =>
                          handleNavigationRoute(
                            "/" + match_and_return_seo_link(blog.b_id)
                          )
                        }
                        src={blog.image_name}
                        alt={`Image for blog titled ${blog.title_name}`}
                        title={`Click to read more about ${blog.title_name}`}
                        effect="blur"
                        width="100%"
                      />
                      <div className="container">
                        <h2
                          style={{
                            display: "webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 1,
                            whiteSpace: "nowrap",
                          }}
                        >
                          {blog.title_name}
                        </h2>

                        <h3
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              truncateWords(blog.tag_line)
                            ),
                          }}
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 3,
                            lineHeight: "1.5em",
                          }}
                        ></h3>
                      </div>
                    </div>
                    <div className="container">
                      <div
                        className="ReadMorediv"
                        onClick={() =>
                          handleNavigationRoute(
                            "/" + match_and_return_seo_link(blog.b_id)
                          )
                        }
                      >
                        <div className="explorBttn blogReadMore">
                          <button
                            className="explorBttn btn-push-left"
                            data-hover="CLICK ME!"
                            href="#"
                          >
                            <span>Read More</span>
                          </button>
                        </div>

                        <div className="dateAndTxtDiv">
                          <p>{blog.author}</p>
                          <p>{formatDateString(blog.entry_date)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {/**----this is the Load more Button  */}
          <div className="loadMoreBttn">
            {!loading && (
              <button type="button" onClick={handleLoadMore}>
                {allBlogsLoaded ? "See Less" : "Load More"}
              </button>
            )}
            {loading && (
              <div className="spinner-square">
                <div className="square-1 square"></div>
                <div className="square-2 square"></div>
                <div className="square-3 square"></div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
