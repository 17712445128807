import React, { useState, useEffect } from "react";
import industryDetailImg from "../Assets/industryDetail.jpg";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import "./Css/BlogDetail.css";
import "./Css/IndustryDetail.css";
import { useLocation } from "react-router-dom";
export default function IndustryDetail() {
  const location = useLocation();
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");
  const [textWidth2, setTextwidth2] = useState("100%");
  const [h1Width, setH1Width] = useState("0%");

  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("whatWedo");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top >= 0 && h1Top <= windowHeight * 0.8) {
          setH1Width("100%");
        } else {
          setH1Width("0%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //Foood Industry Data
  const foodInduData = [
    {
      content:
        "The food industry is becoming more competitive every day. Whether you’re running a local restaurant, a boutique hotel, or a well-known café, having a strong online presence is no longer optional,it’s a necessity. That’s why Digital Marketing for the Food Industry is more important than ever. People are searching for places to dine, order food, or book stays, and your business must be visible when and where they are looking.",
    },
    {
      content:
        "At The Fox Advertisement , we specialize in Digital Marketing for the Food Industry to help your business attract more customers, grow your brand, and stand out from the competition. Our goal is to drive more traffic to your restaurant or hotel, ensuring your tables are always full and your online orders are constantly coming in.",
    },
  ];
  const foodInduData2 = [
    {
      content:
        "The Fox Advertisement  is dedicated to helping your food business succeed. Our expertise in Digital Marketing for the Food Industry allows us to craft strategies that are specifically designed to meet the needs of restaurants, hotels, and cafes. From optimizing your online presence to running targeted advertising campaigns, we do everything to make sure your customers find you online.",
    },
    {
      content:
        "We focus on what really matters: getting more people to your business. Whether it's through social media marketing, search engine optimization, or reputation management, our digital marketing food industry services cover every aspect of your online strategy.",
    },
  ];
  const foodInduData3 = [
    {
      title: "Local SEO and Online Visibility",
      content:
        "When customers are searching for best restaurant near me your business should be the first one they see. At The Fox Advertisement , we use Digital Marketing on the Food Service Industry to improve your online visibility. With local SEO strategies, we make sure your restaurant or hotel ranks high in search results, driving more customers through your doors.",
    },
    {
      content:
        "We focus on what really matters: getting more people to your business. Whether it's through social media marketing, search engine optimization, or reputation management, our digital marketing food industry services cover every aspect of your online strategy.",
    },
  ];

  ///main data
  const data = [
    {
      title: "Proven Results",
      description:
        "With Digital Marketing on the Food Service Industry, we have a track record of helping businesses grow. Our tailored approach has helped restaurants and hotels increase their customer base and revenue by using effective digital strategies. We know what works and we’ve seen the results first-hand.",
    },
    {
      title: "Industry Experience",
      description:
        "The food industry is unique, and so is your business. At The Fox Advertisement , we understand the specific challenges you face. We’ve been working in Digital Marketing for the Food Industry long enough to know what drives people to visit a restaurant or book a hotel. Our deep knowledge of the industry allows us to create marketing strategies that speak directly to your target audience.",
    },
    {
      title: "A Personalized Approach",
      description:
        "No two businesses are the same, and that’s why we don’t believe in one-size-fits-all solutions. Our digital marketing food industry services are personalized to meet your unique needs. We’ll take the time to understand your goals and challenges, so our marketing reflects exactly what you need to grow.",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth2("100%");
      setTextwidth("90%");
      setTextwidth2("0");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const [visibleItems, setVisibleItems] = useState([]);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibleItems((prev) => [...prev, entry.target.id]);
      } else {
        setVisibleItems((prev) => prev.filter((id) => id !== entry.target.id));
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.8, // 80% of the element should be visible
    });

    const elements = document.querySelectorAll(".item");
    elements.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="blogDetaildiv">
      <Header />
      <div className="searvicDEtailDiv" style={{ overflow: "hidden" }}>
        <div className="heroServive">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="leftService">
                <div
                  className="overlay"
                  style={{
                    width: overlayWidth,
                    transition: "width 1.3s ease-in-out",
                  }}
                ></div>
                <div className="imgOverlAy"></div>
                <img
                  src={industryDetailImg}
                  alt="The Fox Advertisement"
                  title="The Fox Advertisement"
                  loading="lazy"
                  className="responsive-img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="rightService">
                <div className="detailServHeroSubBlogg">
                  <div
                    className="overlaytEXT"
                    style={{
                      width: textWidth2,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>

                  {/**-----------Food Industry Url Heading------------ */}

                  <div>
                    <h2>
                      Why Digital Marketing is Essential for the Food Industry?
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <div className="avhivedDiv">
          {/*---------------Food Indutry Data---------------- */}
          {foodInduData.map((section, index) => (
            <div key={index} className="blogDetaiuldata">
              <p>{section.content}</p>
            </div>
          ))}
        </div>
        <h1 className="mt-4" style={{ textAlign: "center", fontSize: "30px" }}>
          Our Role in Your Business Success{" "}
        </h1>
        <div className="avhivedDiv">
          {/*---------------Food Indutry Data---------------- */}
          {foodInduData2.map((section, index) => (
            <div key={index} className="blogDetaiuldata">
              <p>{section.content}</p>
            </div>
          ))}
        </div>
        <h1 className="mt-4" style={{ textAlign: "center", fontSize: "30px" }}>
          How We Help You Get More Customers for Your Hotel/Restaurant
        </h1>
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}
          {foodInduData3.map((section, index) => (
            <div key={index} className="blogDetaiuldata">
              <strong>{section.title}</strong>

              <p>{section.content}</p>
            </div>
          ))}
        </div>
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Social Media Marketing</strong>

            <p>
              Social media is a huge part of Digital Marketing for the Food
              Industry. Platforms like Instagram and Facebook allow you to
              showcase your dishes, interact with customers, and create buzz
              around your restaurant or hotel. We’ll help you create engaging
              content that highlights your unique offerings, making sure you
              attract more followers and, most importantly, more customers.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Online Reviews and Reputation Management</strong>

            <p>
              A strong online reputation is vital for the food service industry.
              We manage your online reviews and ensure that customers see the
              best side of your business. Whether it's responding to customer
              feedback or encouraging positive reviews, our digital marketing
              food industry strategies will help build your credibility and
              increase your customer base.
            </p>
          </div>
        </div>{" "}
        <h3 className="mt-3 " style={{ fontSize: "30px", textAlign: "center" }}>
          How Our Services Will Help You Grow
        </h3>
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Targeting the Right Audience</strong>

            <p>
              A strong online reputation is vital for the food service industry.
              We manage your online reviews and ensure that customers see the
              best side of your business. Whether it's responding to customer
              feedback or encouraging positive reviews, our digital marketing
              food industry strategies will help build your credibility and
              increase your customer base.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Increasing Online Orders and Bookings</strong>

            <p>
              With more customers ordering food or booking stays online, your
              website needs to be optimized for both. We help you streamline
              your online ordering system and booking process, making it easier
              for customers to choose you. Through effective Digital Marketing
              for the Food Industry, we drive more online sales and bookings,
              helping your business grow..
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Tracking and Reporting</strong>

            <p>
              We don’t just set up campaigns and leave them running. Our team at
              The Fox Advertisement provides detailed reports on how your
              digital marketing efforts are performing. We track the success of
              each campaign and adjust where needed to ensure continued growth.
              With digital marketing food industry strategies that are based on
              data, we help you make informed decisions.
            </p>
          </div>
        </div>{" "}
        <h3 className="mt-3 " style={{ fontSize: "30px", textAlign: "center" }}>
          Services We Offer for the Food Industry
        </h3>
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Search Engine Optimization (SEO)</strong>

            <p>
              Ranking higher in search results is crucial for any business. Our
              Digital Marketing for the Food Industry includes SEO services that
              ensure your restaurant or hotel shows up when people are searching
              for dining options or accommodations. We optimize your website,
              research the best keywords, and make sure your business stands out
              online.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Social Media Marketing</strong>

            <p>
              Social media is a powerful tool for food businesses. Our digital
              marketing food industry services include creating eye-catching
              content for platforms like Instagram and Facebook, where potential
              customers are looking for dining inspiration. We help you build a
              loyal following and drive engagement through targeted posts and
              promotions.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Paid Advertising (Google Ads, Facebook Ads)</strong>

            <p>
              Paid advertising is an essential part of Digital Marketing on the
              Food Service Industry. We create and manage ad campaigns that are
              designed to bring in customers who are ready to order or book.
              From Google Ads to Facebook Ads, we use targeted ads to ensure
              you’re reaching the right people at the right time.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Content Creation</strong>

            <p>
              Great content is what drives customer engagement. Our team at The
              Fox Advertisement creates professional photos, videos, and written
              content that showcase your food, service, and experience. By
              creating content that resonates with your target audience, our
              Digital Marketing for the Food Industry helps you stand out.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Reputation Management</strong>

            <p>
              Your online reputation is key to attracting new customers. We
              manage your reviews and ensure that your reputation remains
              strong. With our digital marketing food industry services, we help
              you maintain a positive image that keeps customers coming back.
            </p>
          </div>
        </div>{" "}
        <div className="avhivedDiv2">
          {/*---------------Food Indutry Data---------------- */}

          <div className="blogDetaiuldata">
            <strong>Website Design & Development</strong>

            <p>
              Your website is the face of your business online. We design and
              develop user-friendly websites that make it easy for customers to
              order online or make a reservation. With Digital Marketing for the
              Food Industry, we ensure your website is optimized to convert
              visitors into customers.
            </p>
          </div>
        </div>{" "}
      </div>
      <div className="whatWeDo mt-4" id="whatWedo">
        <div className="whatWEdoHead">
          {" "}
          <h1
            style={{
              width: h1Width,
            }}
          >
            Why Choose The Fox Advertisement for Your Digital Marketing ?
          </h1>
        </div>
        <div className="whatWeDoContent">
          <div className="container-lg">
            {data.map((item, index) => (
              <div
                className="row align-items-center justify-content-center marginClass "
                key={index}
              >
                <div
                  className={`col-lg-3 col-md-4 col-12 item paddingNoneee`}
                  id={`item-${index}`}
                >
                  <div className="underDataWhatwedo industryDetailcont">
                    <span>Proven Results</span>
                    <h1
                      style={{
                        opacity: visibleItems.includes(`item-${index}`) ? 1 : 0,
                        transition: "opacity 0.8s ease-in-out",
                      }}
                    >
                      {item.title}
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 col-12">
                  <p
                    style={{
                      opacity: visibleItems.includes(`item-${index}`) ? 1 : 0,
                      transition: "opacity 0.8s ease-in-out",
                    }}
                  >
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
