import React, { useState, useRef, useEffect } from "react";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import "./Css/ServiceDetails.css";
import { useLocation } from "react-router-dom";
import {
  formatDateString,
  handleError,
  handleLinkClick,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  blog_detail,
} from "../ServiceConnection/serviceconnection";
import DOMPurify from "dompurify";
import Loader from "./Reapeting Componets/Loader";
export default function ServiceDetails() {
  const [inView, setInView] = useState([]);
  const headingsRef = useRef([]);
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);

  console.log(currentUrl);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = Number(entry.target.dataset.index);
          if (entry.isIntersecting) {
            setInView((prev) => [...prev, index]);
          } else {
            setInView((prev) => prev.filter((i) => i !== index));
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    headingsRef.current.forEach((heading) => {
      observer.observe(heading);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");
  const [textWidth2, setTextwidth2] = useState("100");
  const [blogPosts, setcancelledData] = useState([]);
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);

  useEffect(() => {
    master_data_get_details();
  }, [currentUrl]);

  const master_data_get_details = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("current_url", "/" + currentUrl);
    await server_post_data(blog_detail, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          if (final_data.blog.length > 0) {
            setcancelledData(final_data.blog[0]);
          }
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth2("100%");
      setTextwidth("90%");
      setTextwidth2("0");
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {showLoaderAdmin && <Loader />}
      <Header />
      <div className="searvicDEtailDiv" style={{ overflow: "hidden" }}>
        <div className="heroServive">
          <div className="row  align-items-center">
            <div className="col-lg-6 col-12">
              <div className="leftService">
                <div
                  className="overlay"
                  style={{
                    width: overlayWidth,
                    transition: "width 1.3s ease-in-out",
                  }}
                ></div>
                <img
                  src={blogPosts.image_name}
                  alt={blogPosts.title_name}
                  title={blogPosts.title_name}
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="rightService">
                <h1
                  style={{
                    width: textWidth,
                    transition: "width 1.8s ease-in-out",
                  }}
                >
                  {blogPosts.title_name}
                </h1>
                <div className="detailServHeroSub">
                  <div
                    className="overlaytEXT"
                    style={{
                      width: textWidth2,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>
                  <h2>{blogPosts.tag_line}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="serviceDataDet">
        <div className="disSerData">
          {/* <h1

            style={{
              transition: "width 1s ease-in-out",
              maxWidth: 'fit-content'
            }}
          >
            {blogPosts.title}
          </h1> */}
        </div>
        <div className="col-8 mx-auto">
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(blogPosts.description),
            }}
          ></p>
        </div>
      </div>

      <Footer />
    </>
  );
}
