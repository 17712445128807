import { useEffect, useState } from "react";
import "./Css/Service.css";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import BrandTrusted from "./BrandTrusted";
import seriveHero from "../Assets/seriveHero.jpg";
import OurSkill from "./OurSkill";
import OurServices from "./OurServices";
export default function Service() {
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth("fit-content");
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  const data = [
    {
      title: "Digital Marketing Strategy & Branding",
      description:
        "At The Fox Advertisement, we provide you with the best digital marketing and branding strategy. We pride ourselves on delivering customised digital marketing strategies that cater specifically to your unique needs and goals. At The Fox Advertisement, we aim to help you achieve lasting success in the digital world. By providing tailored digital marketing strategies that are as unique as your business.",
    },
    {
      title: "Digital Marketing Strategy & Branding",
      description:
        "At The Fox Advertisement, we provide you with the best digital marketing and branding strategy. We pride ourselves on delivering customised digital marketing strategies that cater specifically to your unique needs and goals. At The Fox Advertisement, we aim to help you achieve lasting success in the digital world. By providing tailored digital marketing strategies that are as unique as your business.",
    },
    {
      title: "Digital Marketing Strategy & Branding",
      description:
        "At The Fox Advertisement, we provide you with the best digital marketing and branding strategy. We pride ourselves on delivering customised digital marketing strategies that cater specifically to your unique needs and goals. At The Fox Advertisement, we aim to help you achieve lasting success in the digital world. By providing tailored digital marketing strategies that are as unique as your business.",
    },
  ];

  const [h1Width, setH1Width] = useState("0%");
  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("whatWedo");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top >= 0 && h1Top <= windowHeight * 0.8) {
          setH1Width("100%");
        } else {
          setH1Width("0%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <Header />
      <div style={{ overflow: "hidden" }}>
        <div className="ServiceContainer">
          <div className="heroServive">
            <div className="row  align-items-center">
              <div className="col-lg-6 col-12">
                <div className="leftService">
                  <div
                    className="overlay"
                    style={{
                      width: overlayWidth,
                      transition: "width 1.3s ease-in-out",
                    }}
                  ></div>
                  <img
                    src={seriveHero}
                    alt="The Fox Advertisement"
                    title="The Fox Advertisement"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="rightService">
                  <h1
                    style={{
                      width: textWidth,
                      transition: "width 1.8s ease-in-out",
                    }}
                  >
                    Be partner and
                  </h1>
                  <h1
                    style={{
                      width: textWidth,
                      transition: "width 1.8s ease-in-out",
                    }}
                  >
                    Witness the{" "}
                  </h1>
                  <h1
                    style={{
                      width: textWidth,
                      transition: "width 1.8s ease-in-out",
                    }}
                  >
                    growth of you
                  </h1>

                  <div
                    className="subHeroCOntnt"
                    style={{
                      width: textWidth,
                      transition: "width 1.8s ease-in-out",
                    }}
                  >
                    <span>Digital Marketing</span>
                    <span>Performance Marketing</span>
                    <span>Marketing Strategy</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/**-----------WHat We Do ----- */}
          <div className="whatWeDo" id="whatWedo">
            <div className="whatWEdoHead">
              {" "}
              <h1
                style={{
                  width: h1Width,
                }}
              >
                What We Do
              </h1>
            </div>
            <div className="whatWeDoContent">
              <div className="container-lg">
                {data.map((item, index) => (
                  <div
                    className="row align-items-center justify-content-center marginClass"
                    key={index}
                  >
                    <div className="col-lg-3 col-md-4 col-12 ">
                      <div className="underDataWhatwedo">
                        <span>{item.title}</span>
                        <h1>{item.title}</h1>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-8 col-12 ">
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <OurSkill />
        <OurServices />
        <BrandTrusted />
        <Footer />
      </div>
    </>
  );
}
