import React, { useState, useEffect } from "react";
import Fotterfox from "../../Assets/Fotterfox.png";
import ToggleImg from "../../Assets/headerToggle.svg";
import crossToggle from "../../Assets/crossToggle.svg";
import "../Css/Header.css";
import NavigationWithAnimation from "../../CommonJquery/NavigationWithAnimation";
import heroCar1 from "../../Assets/heroCar1.jpg";
import { handleLinkClick } from "../../CommonJquery/CommonJquery";
const Header = () => {
  const { handleNavigationRoute } = NavigationWithAnimation();
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleNavbarMobile, setisVisibleNavbarMobile] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [active, setActive] = useState(false);
  const handleRoutePage = (path) => {
    const currentPath = window.location.pathname;
    if (currentPath === path) {
      window.location.reload();
      setIsOpen(false);
      setIsExpanded(false);
    } else {
      handleNavigationRoute(path);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    if (!isOpen) {
      setIsOpen(true);
      setIsExpanded(false);
    } else if (!isExpanded) {
      setIsExpanded(true);
    }
  };
  useEffect(() => {
    const timeoutDuration = window.innerWidth < 768 ? 300 : 1000;
    if (isExpanded) {
      const timeoutId = setTimeout(() => {
        setIsOpen(false);
        setIsExpanded(false);
      }, timeoutDuration);
      return () => clearTimeout(timeoutId);
    }
  }, [isExpanded]);
  const buttonLabels = [
    { label: "About", path: "/about" },
    { label: "Services", path: "/services" },
    { label: "Portfolio", path: "/portfolio" },
    { label: "Fox Academy", path: "/fox_academy" },
    { label: "Industry", path: "/industry" },
    { label: "Blog", path: "/blog" },
    { label: "Career", path: "/career" },
    { label: "Contact", path: "/contact" },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive(true);
    }, 3300);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="HeaderCont">
        <div
          className={`logoimg downTOUp ${isVisible ? "slideUp" : ""}`}
          style={{ cursor: "pointer" }}
        >
          <img
            onClick={() => handleNavigationRoute("/")}
            className="logoImgtxt"
            src={Fotterfox}
            alt="The Fox Advertisement"
            title="The Fox Advertisement"
          />
        </div>
        <div
          className={`logoimg2 downTOUp ${isVisible ? "slideUp" : ""}`}
          style={{ cursor: "pointer" }}
          onClick={handleToggle}
        >
          <img
            src={ToggleImg}
            alt="The Fox Advertisement"
            title="The Fox Advertisement"
          />
        </div>
        <div
          className={`toggleBarContnrr ${isOpen ? "open" : ""} ${
            isExpanded ? "expanded" : ""
          }`}
        >
          <div className="righSideImg">
            <img
              style={{
                transition: "opacity 0.8s ease",
                opacity: clickCount >= 2 && clickCount % 2 === 0 ? 0 : 1,
              }}
              src={heroCar1}
              alt="Hero Car Image for SEO"
              title="Hero Car Image for SEO"
            ></img>
          </div>
          <div className={`lefySide`}>
            <div className={`logoAndCrossIcon `}>
              {" "}
              <img
                onClick={() => handleNavigationRoute("/")}
                className="headerIconImgg"
                src={Fotterfox}
                alt="The Fox Advertisement "
                title="The Fox Advertisement "
              />
              <img
                onClick={handleToggle}
                className="toggleCloseImg"
                src={crossToggle}
                alt="The Fox Advertisement "
                title="The Fox Advertisement "
              />
            </div>

            <div className={`NavbarDatas ${active ? "active" : ""}`}>
              {buttonLabels.map((button, index) => (
                <button
                  key={index}
                  className={active ? "active" : ""}
                  onClick={() => handleRoutePage(button.path)}
                >
                  {button.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
