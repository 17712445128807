import React, { useState, useEffect } from "react";
import industryDetailImg from "../Assets/industryDetail.jpg";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import "./Css/BlogDetail.css";
import "./Css/IndustryDetail.css";
import { useLocation } from "react-router-dom";
export default function HealthCareIndustry() {
  const location = useLocation();
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");
  const [textWidth2, setTextwidth2] = useState("100%");
  const [h1Width, setH1Width] = useState("0%");

  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("whatWedo");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top >= 0 && h1Top <= windowHeight * 0.8) {
          setH1Width("100%");
        } else {
          setH1Width("0%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // Blog content stored in an array to map through

  ///health care Data
  const healthcaredata = [
    {
      content:
        "Hospitals and healthcare providers are facing increasing competition as more patients rely on online resources for healthcare decisions. Having a strong digital presence is now essential for healthcare providers. With more patients turning to digital platforms for information about healthcare services, digital marketing in the healthcare industry has become crucial for attracting and retaining patients. At Fox Advertisement, we understand the importance of digital marketing in healthcare and provide specialized digital marketing services in the healthcare industry to help hospitals and clinics grow their reputation, reach a wider audience, and establish themselves as trusted care providers",
    },
    {},
  ];
  const healthcaredata2 = [
    {
      title: "Why Digital Marketing is Essential for the Healthcare Industry",
      content:
        "The healthcare industry has evolved, with patients becoming more informed and selective in their healthcare choices. People are researching online to find reliable medical care, which means that if your hospital or clinic isn’t visible online, you might miss out on potential patients. Digital marketing in the healthcare industry enables healthcare providers to increase their online visibility, connect with potential patients, and build trust through relevant content and positive reviews.",
      content2:
        "Through digital marketing for healthcare industry providers, you can effectively promote your services, showcase patient success stories, and build a stronger connection with your community. The importance of digital marketing in healthcare is not just about reaching a broader audience but about creating meaningful engagement with patients, helping them make informed decisions about their health.",
    },
  ];

  ///main data
  const data = [
    {
      title: "Search Engine Optimization (SEO)",
      description:
        "We optimize your website and content to rank higher in search results, making it easier for patients to find your services. With strong healthcare digital marketing SEO tactics, your hospital will be seen by the right audience.",
    },
    {
      title: "Social Media Marketing",
      description:
        "We develop engaging social media campaigns to connect with potential patients and maintain relationships with current ones. Digital marketing for healthcare industry on social media helps to humanize your services and keep your audience engaged.",
    },
    {
      title: "Online Reputation Management",
      description:
        "Your hospital's reputation is critical. Our digital marketing services in the healthcare industry include monitoring reviews and feedback to ensure that potential patients see your positive impact.",
    },
    {
      title: "Content Marketing",
      description:
        "Educating patients through informative blogs, articles, and videos establishes your hospital as a trusted source of medical information. Importance of digital marketing in healthcare lies in providing value to your audience while improving your online presence.",
    },
    {
      title: "Paid Advertising",
      description:
        "We design targeted campaigns using Pay-Per-Click (PPC) and other advertising strategies to ensure your hospital gets noticed quickly and efficiently.",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth2("100%");
      setTextwidth("90%");
      setTextwidth2("0");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const [visibleItems, setVisibleItems] = useState([]);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibleItems((prev) => [...prev, entry.target.id]);
      } else {
        setVisibleItems((prev) => prev.filter((id) => id !== entry.target.id));
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.8, // 80% of the element should be visible
    });

    const elements = document.querySelectorAll(".item");
    elements.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="blogDetaildiv">
      <Header />
      <div className="searvicDEtailDiv" style={{ overflow: "hidden" }}>
        <div className="heroServive">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="leftService">
                <div
                  className="overlay"
                  style={{
                    width: overlayWidth,
                    transition: "width 1.3s ease-in-out",
                  }}
                ></div>
                <div className="imgOverlAy"></div>
                <img
                  src={industryDetailImg}
                  alt="The Fox Advertisement"
                  title="The Fox Advertisement"
                  loading="lazy"
                  className="responsive-img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="rightService">
                <div className="detailServHeroSubBlogg">
                  <div
                    className="overlaytEXT"
                    style={{
                      width: textWidth2,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>

                  {/**------------health Industry Url Heading------------ */}

                  <div>
                    <h2>Why Digital Marketing for the Health Industry?</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <div className="avhivedDiv">
          {/*-------------Health  Care data Top--------------- */}

          {healthcaredata.map((section, index) => (
            <div key={index} className="blogDetaiuldata">
              <p>{section.content}</p>
            </div>
          ))}
          {healthcaredata2.map((section, index) => (
            <div key={index} className="RealIndiData">
              {" "}
              <h5>{section.title}</h5>
              <p>{section.content}</p>
              <p className="mt-2">{section.content2}</p>
            </div>
          ))}
          <h1 style={{ fontSize: "30px" }}>
            How We Attract Patients to Your Hospital
          </h1>
          <p className="mt-2">
            At Fox Advertisement, we focus on providing comprehensive healthcare
            digital marketing solutions that deliver results. We know that each
            healthcare provider is unique, so we create customized strategies
            that align with your goals. Here’s how our digital marketing
            services in the healthcare industry can help:
          </p>
        </div>
      </div>
      <div className="whatWeDo mt-4" id="whatWedo">
        <div className="whatWEdoHead">
          {" "}
          <h1
            style={{
              width: h1Width,
            }}
          >
            Why Digital Marketing Matters in Healthcare
          </h1>
          {/* <p style={{ color: "#5A5C5C " }}>
          There are many digital marketing agencies, but here’s why The Fox
          Advertisement is the best choice for your educational institution:
        </p> */}
        </div>
        <div className="whatWeDoContent">
          <div className="container-lg">
            {data.map((item, index) => (
              <div
                className="row align-items-center justify-content-center marginClass "
                key={index}
              >
                <div
                  className={`col-lg-3 col-md-4 col-12 item paddingNoneee`}
                  id={`item-${index}`}
                >
                  <div className="underDataWhatwedo industryDetailcont">
                    <span>Proven Results</span>
                    <h1
                      style={{
                        opacity: visibleItems.includes(`item-${index}`) ? 1 : 0,
                        transition: "opacity 0.8s ease-in-out",
                      }}
                    >
                      {item.title}
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 col-12">
                  <p
                    style={{
                      opacity: visibleItems.includes(`item-${index}`) ? 1 : 0,
                      transition: "opacity 0.8s ease-in-out",
                    }}
                  >
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-lg">
        <div className="Letgrowdata">
          <h1>The Importance of Digital Marketing in Healthcare</h1>
          <p>
            The importance of digital marketing in healthcare cannot be
            overstated. As patients increasingly turn to the internet to find
            medical care, hospitals and clinics need to be where their patients
            are — online. By investing in digital marketing for healthcare
            industry providers, you can
          </p>
          <li>Improve patient acquisition by reaching a larger audience. </li>{" "}
          <li>
            {" "}
            Build trust and credibility by showcasing success stories, positive
            reviews, and informative
          </li>{" "}
          <li>
            Maintain an active and engaging social media presence that keeps
            your hospital connected with the community.
          </li>{" "}
          <li>
            {" "}
            Ensure that your hospital is one of the top results when patients
            search for local medical care.
          </li>{" "}
          <p>
            Healthcare digital marketing helps you not only to attract patients
            but also to retain them by keeping your hospital at the forefront of
            their minds through consistent online engagement.
          </p>
        </div>
        <div className="Letgrowdata marginClassHealth">
          <h1>
            Why Choose Fox Advertisement for Healthcare Digital Marketing?
          </h1>
          <p>
            When it comes to digital marketing in the healthcare industry, Fox
            Advertisement stands out by providing tailored solutions that meet
            the specific needs of healthcare providers. We understand the
            nuances of digital marketing for healthcare industry services and
            how to best market your hospital or clinic. Our team focuses on
            building strategies that not only improve your online presence but
            also create trust and credibility with your patients.
          </p>

          <div className="marginClassHealth">
            <strong>Experienced Team</strong>
            <p style={{ marginTop: "0.3rem" }}>
              Our team consists of experts in healthcare digital marketing who
              understand the industry inside and out. We know how to create
              campaigns that resonate with patients and encourage them to choose
              your hospital.
            </p>
          </div>
          <div className="marginClassHealth">
            <strong>Search Engine Optimization (SEO)</strong>
            <p style={{ marginTop: "0.3rem" }}>
              We have a track record of delivering measurable results for our
              clients through our comprehensive digital marketing services in
              the healthcare industry. From improved patient acquisition to
              higher online visibility, we ensure that our strategies drive
              success.
            </p>
          </div>
          <div className="marginClassHealth">
            <strong>Customized Approach</strong>
            <p style={{ marginTop: "0.3rem" }}>
              No two hospitals are the same, and neither are their marketing
              needs. We take a personalized approach to digital marketing in the
              healthcare industry, ensuring that the strategies we implement
              align with your goals and resonate with your target audience.
            </p>
          </div>
        </div>
        <div className="Letgrowdata marginClassHealth">
          <h1>Success with Healthcare Digital Marketing</h1>
          <p>
            To highlight the importance of digital marketing in healthcare, one
            of our recent clients, a large hospital group, struggled to attract
            new patients in a competitive market. After partnering with Fox
            Advertisement, we implemented a full-scale digital marketing for
            healthcare industry campaign, focusing on SEO, social media
            marketing, and reputation management.
          </p>
          <p>
            Within six months, their online visibility increased significantly,
            with their website ranking on the first page of search results for
            local healthcare services. The hospital saw a 35% increase in
            patient inquiries, with a higher patient retention rate, thanks to
            targeted healthcare digital marketing strategies.
          </p>
        </div>
        <div className="Letgrowdata marginClassHealth">
          <h1>Let’s Grow Your Hospital’s Reach Together</h1>
          <p>
            At Fox Advertisement, we specialize in digital marketing in the
            healthcare industry, offering customized strategies to help your
            hospital or clinic attract more patients. Our proven approach to
            digital marketing in the healthcare industry will strengthen your
            online presence, increase visibility, and build a trusted
            reputation. With our expertise in digital marketing in the
            healthcare industry, you can focus on providing exceptional care
            while we handle the marketing.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
