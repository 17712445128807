import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";

import NavigationWithAnimation from "../CommonJquery/NavigationWithAnimation";
import "./Css/Hero.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import heroCar1 from "../Assets/heroCar1.jpg";
import heroCar2 from "../Assets/heroCar2.jpg";
import heroCar3 from "../Assets/heroCar3.jpg";
import heroCar4 from "../Assets/heroCar4.jpg";

const Hero = () => {
  const { handleNavigationRoute } = NavigationWithAnimation();
  const [activeSlide, setActiveSlide] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const sliderRef = useRef(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  const slides = [
    {
      image: heroCar1,
      heading: ["SEE YOUR WEBSITE AT", "THE TOP OF", "SEARCH ENGINE"],
      subheading: ["GENERATE REVENUE", "CREATIVE MARKETING STRATEGY"],
      buttonText: "CONTACT US",
    },
    {
      image: heroCar2,
      heading: [
        "BOOST SALES AND INCREASE",
        "ENGAGEMENT WITH",
        " CREATIVE STRATEGY",
        "SOCIAL MEDIA MARKETING",
      ],
      subheading: ["GENERATE REVENUE", "CREATIVE MARKETING STRATEGY"],
      buttonText: "CONTACT US",
    },
    {
      image: heroCar3,
      heading: ["Online Digital", "MARKETING SOLUTION", "167 CLIENTS SERVED!"],
      subheading: ["IN INDIA", "SWEDEN", "AUSTRALIA"],
      buttonText: "BOOK A DEMO",
    },
    {
      image: heroCar4,
      heading: [
        "AI-DRIVEN STRATEGY",
        "DIGITAL SOLUTION",
        "TO BOOST YOUR BUSINESS",
      ],
      subheading: ["GENERATE REVENUE", "CREATIVE MARKETING STRATEGY"],
      buttonText: "CONTACT US",
    },
  ];
  const handleSlideChange = (idx) => {
    setActiveSlide(idx);
    setAnimationKey((prevKey) => prevKey + 1);
    sliderRef.current.slickGoTo(idx);
  };
  return (
    <section className="hero-section">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="heroCOntentContainer">
            <div
              className="col-lg-6 col-12 position-relative BlackColorClass"
              style={{ overflow: "hidden", height: "100%" }}
            >
              <div
                className="overlay"
                style={{
                  width: activeSlide === index ? "0%" : "100%",
                  transition: "width 1.3s ease-in-out",
                }}
              ></div>
              <img
                key={index}
                className="heroLeftImg"
                src={slide.image}
                alt={`A visual representation of ${slide.heading.join(" ")}`}
                title={`Discover our strategy: ${slide.heading.join(", ")}`}
              />
            </div>
            <div className="col-lg-8 col-md-12 rightSideContent">
              <div className="right SideContent">
                {slide.heading.map((text, idx) => (
                  <h2
                    key={`${animationKey}-${idx}`}
                    style={{
                      width: activeSlide === index ? "fit-content" : "0%",
                      maxWidth: activeSlide === index ? "100%" : "0%",
                      transition:
                        "width 1.8s ease-in-out, max-width 1.8s ease-in-out, color 1.8s ease-in-out, background-color 1.8s ease-in-out",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      backgroundColor:
                        activeSlide === index ? "" : "transparent",
                      color: activeSlide === index ? "" : "transparent",
                    }}
                    className="fontSizeAf576"
                  >
                    {text}
                  </h2>
                ))}

                <div className="generativeDiv">
                  {slide.subheading.map((sub, idx) => (
                    <p
                      key={idx}
                      style={{
                        width: activeSlide === index ? "fit-content" : "0%",
                        maxWidth: activeSlide === index ? "100%" : "0%",
                        transition:
                          "width 1.8s ease-in-out, max-width 1.8s ease-in-out, color 1.8s ease-in-out, background-color 1.8s ease-in-out",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        backgroundColor:
                          activeSlide === index ? "" : "transparent",
                        color: activeSlide === index ? "" : "transparent",
                      }}
                    >
                      {sub}
                    </p>
                  ))}
                </div>
                <div className="contactUsBttnHero">
                  <button
                    className={isVisible ? "slideUp" : ""}
                    onClick={() => handleNavigationRoute("/contact")}
                  >
                    {slide.buttonText}
                    <svg>
                      <rect x="0" y="0" width="100%" height="100%"></rect>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className="sliderIndexingShow">
        {slides.map((_, idx) => (
          <button
            key={idx}
            onClick={() => handleSlideChange(idx)}
            className={`slideButton ${activeSlide === idx ? "active" : ""}`}
          >
            {String(idx + 1).padStart(2, "0")}
          </button>
        ))}
      </div>
    </section>
  );
};

export default Hero;
