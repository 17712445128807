import Fotterfox from "../../Assets/Fotterfox.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../Css/Footer.css";
import NavigationWithAnimation from "../../CommonJquery/NavigationWithAnimation";
import { useLocation } from "react-router-dom";
import {
  server_post_data,
  blog,
} from "../../ServiceConnection/serviceconnection";
import {
  formatDateString,
  handleError,
} from "../../CommonJquery/CommonJquery.js";
import { useState, useEffect } from "react";
const footerSections = [
  {
    title: "About Us",
    links: [
      {
        name: "We are a dynamic and innovative digital marketing agency dedicated to helping businesses thrive in the evolving digital landscape.",
        to: "null",
      },
    ],
  },
  {
    title: "Quick Links",
    links: [
      { name: "Home", to: "/" },
      { name: "Services", to: "/services" },
      { name: "Portfolio", to: "/portfolio" },
      { name: "Blog", to: "/blog" },
      { name: "Career", to: "/career" },
      { name: "Contact", to: "/contact" },
    ],
  },
  // {
  //   title: "Our Services",
  //   links: [
  //     { name: "Content Marketing", to: "/services" },
  //     { name: "Social Media Marketing", to: "/services" },
  //     { name: "UI/UX Designing", to: "/services" },
  //     { name: "Search Engine Optimization", to: "/services" },
  //     { name: "Performance Services", to: "/services" },
  //     { name: "Lead Generation Services", to: "/services" },
  //   ],
  // },
];

export default function Footer() {
  const location = useLocation();
  const { handleNavigationRoute } = NavigationWithAnimation();
  const footerConact = location.pathname === "/contact";
  const handleNavigation = (to) => {
    if (to === "/" && location.pathname === "/") {
      window.scrollTo(0, 0);
    } else {
      handleNavigationRoute(to);
    }
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "services/serviceDetails/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      console.log("data_seo_link", data_seo_link);
      const matchedItem = SEOloop.find((data) => {
        console.log("data.call_function_name", data.call_function_name);
        console.log("data.call_function_name", data);
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    console.log("data_seo_link_final", data_seo_link_final);
    return data_seo_link_final;
  };

  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [blogData, setcancelledData] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  useEffect(() => {
    master_data_get();
  }, []);
  function truncateWords(text, wordLimit) {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + " ..."
      : text;
  }
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 2);
    await server_post_data(blog, fd)
      .then((Response) => {
        // console.log(Response);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          console.log(final_data);
          setcancelledData(final_data.blog);
          setSEOloop(final_data.seo_list);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // console.log(SEOloop)
  return (
    <div className={`${footerConact ? "FooterSectionHome" : "FooterSection"}`}>
      <div className="">
        <div className="row  FooterDowndata">
          <div className="col-lg-3 col-sm-6 col-12">
            <h5>About Us</h5>
            <ul className="list-unstyled ListData">
              <li>
                <p>
                  We are a dynamic and innovative digital marketing agency
                  dedicated to helping businesses thrive in the evolving digital
                  landscape.
                </p>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-6">
            <h5>Quick Links</h5>
            <ul className="list-unstyled ListData">
              <li>
                <button
                  onClick={() => handleNavigation("/")}
                  className="navigate-button"
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation("/services")}
                  className="navigate-button"
                >
                  Services
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation("/portfolio")}
                  className="navigate-button"
                >
                  Portfolio
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation("/blog")}
                  className="navigate-button"
                >
                  Blog
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation("/career")}
                  className="navigate-button"
                >
                  Career
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation("/contact")}
                  className="navigate-button"
                >
                  Contact
                </button>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-6">
            <h5>Our Services</h5>
            <ul className="list-unstyled ListData">
              {blogData.slice(0, 6).map((service, index) => (
                <li key={index}>
                  <button
                    onClick={() =>
                      handleNavigationRoute(
                        "/" + match_and_return_seo_link(service.b_id)
                      )
                    }
                    className="navigate-button"
                  >
                    {service.title_name.split(" ").slice(0, 3).join(" ")}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Section */}
          <div className="col-lg-3 col-sm-6">
            <h5>Contact </h5>
            <div className="d-flex gap-1 ContactSec">
              <button
                onClick={() =>
                  (window.location.href = "mailto:bdm@thefoxadvertisement.com")
                }
                title="Email Us"
                style={{
                  color: "white",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <i className="bi bi-envelope-fill me-2"></i> Email:
                bdm@thefoxadvertisement.com
              </button>

              <button
                onClick={() => (window.location.href = "tel:+919209403405")}
                title="Call Us"
                style={{
                  color: "white",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <i className="bi bi-phone-fill me-2"></i> Phone: +91-9209403405
              </button>

              <button
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/place/The+Fox+Advertisement:+Strategy+%26+Digital+Marketing+Company+in+Bhopal+%7C+SEO+%7C+Social+Media+Marketing+%7C+Lead+Generation/@23.2297519,77.4323537,604m/data=!3m1!1e3!4m6!3m5!1s0x397c432133b5e2f9:0xad60617b8b70df60!8m2!3d23.2325199!4d77.4358894!16s%2Fg%2F11qn5p6k2l!5m1!1e2?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D",
                    "_blank"
                  )
                }
                title="Visit Our Office"
                style={{
                  color: "white",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <i
                  className="bi bi-geo-alt-fill me-2"
                  style={{ marginRight: "5px" }}
                ></i>{" "}
                Address: 2nd Floor, Gargi Rani Complex, Plot No-96, near Hakeem
                Restaurant, Zone-II, Mp Nagar, Bhopal, Madhya Pradesh 462011
              </button>
            </div>
          </div>
        </div>

        <div className="socialMediaLink">
          <div className="mt-4">
            <h5>Follow Us</h5>
            <div className="d-flex SocialIcons">
              <a
                href="https://www.facebook.com/thefoxadvertisement"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
                alt="Facebook Icon"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/thefoxadvertisement_/?igsh=NGsycWpvZHh3cTJz#"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
                alt="Instagram Icon"
              >
                <i className="fab fa-instagram"></i>
              </a>

              <a
                href="https://www.linkedin.com/company/thefoxadv/"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
                alt="LinkedIn Icon"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://x.com/thefoxadve7588"
                target="_blank"
                rel="noopener noreferrer"
                title="Twitter"
                alt="Twitter Icon"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://youtube.com/@thefoxadvertisement"
                target="_blank"
                rel="noopener noreferrer"
                title="Youtube"
                alt="Youtube Icon"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <div className="mt-4">
            <div className="d-flex" onClick={scrollTop}>
              <img
                src={Fotterfox}
                alt="The Fox Advertisement "
                title="The Fox Advertisement"
                style={{
                  width: "110px",
                  height: "60px",
                  cursor: "pointer",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              />
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="row">
          <div className="copyRightText">
            <p>© Copyright 2024 The Fox Advertisement</p>
          </div>
        </div>
      </div>
    </div>
  );
}
