import { useEffect, useState } from "react";
import "./Css/OurServices.css";
import ourService1 from "../Assets/ourService1";
import serv2 from "../Assets/serv2.jpg";
import serv3 from "../Assets/serv3.jpg";
import serv4 from "../Assets/serv4.jpg";
import serv5 from "../Assets/serv5.jpg";
import serv6 from "../Assets/serv6.jpg";
import NavigationWithAnimation from "../CommonJquery/NavigationWithAnimation";
import { server_post_data, blog } from "../ServiceConnection/serviceconnection";
import { formatDateString, handleError } from "../CommonJquery/CommonJquery.js";
import DOMPurify from "dompurify";
import Loader from "./Reapeting Componets/Loader";
export default function OurServices() {
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [services, setcancelledData] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "services/serviceDetails/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  useEffect(() => {
    master_data_get();
  }, []);
  function truncateWords(text, wordLimit) {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + " ..."
      : text;
  }
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 2);
    await server_post_data(blog, fd)
      .then((Response) => {
        console.log(Response);
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          console.log(final_data);
          setcancelledData(final_data.blog);
          setSEOloop(final_data.seo_list);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  const { handleNavigationRoute } = NavigationWithAnimation();

  const [overlayWidth, setOverlayWidth] = useState("100%");

  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("ourServicveData");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top <= windowHeight * 0.7) {
          setOverlayWidth("0%");
        } else {
          setOverlayWidth("100%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {showLoaderAdmin && <Loader />}
      <div className="ourServiVemainDiv">
        <div className="OurserHead">
          <div className="d-flex flex-column  align-items-center">
            <h1> Our Services</h1>
            <p>We Create Best Products</p>
          </div>
        </div>

        <div className="ourSerViceContentDiv">
          <div id="ourServicveData" className="row">
            {services &&
              services.length > 0 &&
              services.map((service, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12 mt-4">
                  <div className="ourSeriveData">
                    <div
                      className="serViceImgConty"
                      style={{ position: "relative" }}
                    >
                      <div
                        className="overlay"
                        style={{
                          width: overlayWidth,
                          transition: "width 0.8s ease-in-out",
                        }}
                      ></div>
                      <img
                        src={service.image_name}
                        alt={`Image representing ${service.title_name}`}
                        title={service.title_name}
                      />
                    </div>

                    <div className="downDataSerice">
                      <div className="container">
                        <h1>{service.title_name}</h1>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              truncateWords(service.tag_line)
                            ),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="OurSerReadButton">
                    <div
                      className="explorBttn"
                      onClick={() =>
                        handleNavigationRoute(
                          "/" + match_and_return_seo_link(service.b_id)
                        )
                      }
                    >
                      <button
                        className="explorBttn btn-push-left"
                        data-hover="CLICK ME!"
                      >
                        <span>Read More</span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
